<template>
    <b-container fluid>
      <b-row v-if="rowsShow.length > 0">
        <b-col>
          <div v-for="row in rowsShow" :key="'recursive-row-'+ row.id">
            <router-link :to="{ name: 'TaxonomyItem', params: { id: row.id }}"><h5>{{row.name}}</h5></router-link>
            <taxonomy-recursive-simple
              :rows="rows"
              :parent_id="row.id"
              :level="level + 1"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'TaxonomyRecursiveSimple',
  props: ['level', 'parent_id', 'rows'],
  computed: {
    rowsShow: function () {
      let results = []
      this.rows.forEach(x => {
        if (this.level === 0) {
          if (x.parents.length === 0) {
            results.push(x)
          }
        } else {
          let found = false
          if (x.parents.length > 0) {
            x.parents.forEach(parent => {
              if (parent.id === this.parent_id) {
                found = true
              }
            })
          }
          if (found) {
            results.push(x)
          }
        }
      })
      results = _.orderBy(results, ['name'], ['asc'])
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  }
}
</script>
