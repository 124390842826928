<template>
  <b-col>
    <b-card class="my-3 mb-2" v-if="rowsShow.length > 0 && rowsShow.length < rows.length">
      <h3>Search results:</h3>
      <div v-for="item in rowsShow" :key="'searchrow'+item.id">
        <router-link :to="{ name: 'TaxonomyItem', params: { id: item.id }}"><h5>{{item.numberLong}} - {{item.name}}</h5></router-link>
      </div>
    </b-card>
    <b-card class="my-3 mb-2">
      <line-item
        :rows="rowsShow"
        :parent_id="null"
        :level="0"
      />
    </b-card>
  </b-col>
</template>

<script>
import LineItem from '@/components/taxonomy/RecursiveDown.vue'

export default {
  name: 'Taxonomy',
  components: {
    LineItem
  },
  computed: {
    rowsShow () {
      return this.$store.state.rowsShow
    },
    rows () {
      return this.$store.state.rows
    },
    sortDirection: {
      get () {
        return this.$store.state.sortDirection
      },
      set (payload) {
        this.$store.commit('setSortDirection', payload)
      }
    },
    sortProperty: {
      get () {
        return this.$store.state.sortProperty
      },
      set (payload) {
        this.$store.commit('setSortProperty', payload)
      }
    },
    sortPropertyOptions () {
      return this.$store.state.sortPropertyOptions
    },
    totalRows () {
      return this.rowsShow.length
    }
  },
  created: function () {
    this.$stat.log({ page: 'taxonomy', action: 'open taxonomy' })
  },
  data () {
    const data = {
      sortDirectionOptions: [
        { value: 'asc', text: 'ascending' },
        { value: 'desc', text: 'descending' }
      ]
    }
    return data
  },
  methods: {
  }
}
</script>

<style>
</style>
